import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import React, { useCallback, useEffect, useState } from "react";
import CallButton from "../../../../commons/callButton/CallButton";

const MicButton = ({ room }) => {
  const [isAudioStarted, setIsAudioStarted] = useState(false);
  const [isMicMuted, setIsMicMuted] = useState(false);

  const stream = room?.getMediaStream();

  // Alternar micrófono (encendido/apagado)
  const handleToggleMicButton = useCallback(async () => {
    if (isAudioStarted) {
      if (isMicMuted) {
        await stream.unmuteAudio();
        setIsMicMuted(false);
      } else {
        await stream.muteAudio();
        setIsMicMuted(true);
      }
    } else {
      // Iniciar el audio si aún no está activo
      await stream.startAudio();
      setIsAudioStarted(true);
    }
  }, [stream, isAudioStarted, isMicMuted]);

  // Intentar iniciar automáticamente el audio al montar el componente
  useEffect(() => {
    const startAudioAutomatically = async () => {
      try {
        const permissions = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        if (permissions) {
          await stream.startAudio();
          setIsAudioStarted(true);
        }
      } catch (error) {
        console.error(
          "Error al intentar iniciar el audio automáticamente:",
          error,
        );
      }
    };

    startAudioAutomatically();
  }, [stream]);

  return (
    <CallButton
      src={isMicMuted || !isAudioStarted ? <MicOffIcon /> : <MicIcon />}
      action={handleToggleMicButton}
    />
  );
};

export default MicButton;
